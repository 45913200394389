import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/cognitiveStyle.js";
const useStyles = makeStyles(styles);

export default function FounderLetterSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem xs={11} sm={10} md={6}>
            <h2 className={classes.title}>
              What do video games and school have in common?
            </h2>
            <h5 className={classes.description}>
              <b>Letter from the founder</b>
            </h5>
            <p className={classes.description} align="justify">
              Ever wondered why you accomplished this stupidly long quest
              composed with a lot of sub-objectives so naturally? All that to
              realize at the end that knowing what it would take, you{"'"}d
              instead not doing it? But hey, it{"'"}s too late, you have already
              done it.
            </p>
            <p className={classes.description} align="justify">
              Ever wondered how much you have been through during your long
              years of school, either if you did good or bad? Each day, you
              looked into as many as eight different topics for hours. And even
              if you were not up to speed at times, the very next day, you were
              back at it, unrelenting.
            </p>
            <p className={classes.description} align="justify">
              So what{"'"}s video games and school have in common? They are both
              not letting you have many choices. And that makes you go through
              them.
            </p>
            <p className={classes.description} align="justify">
              Even if the starting state of choice is slightly different in both
              cases, after all, you decided to launch that game, but it might
              not be the same with school. When you think of it, you have done
              both regardless of theirs complexity.
            </p>
            <p className={classes.description} align="justify">
              This component of not having choices allows you to do something
              despite your today circumstances or state of mind like motivation.
              No matter how great the task is. Not having too much choice puts
              you in doer mode because there isn{"'"}t much about it to think.
            </p>
            <p className={classes.description} align="justify">
              This is why Live Planner uses this idea by giving you simple and
              tailored planning to follow for your day. It presents to you a
              default option, the one all people most likely chose, for you to
              default into this doer mode.
            </p>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
