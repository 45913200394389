import { container } from "assets/jss/material-kit-react.js";

const cognitiveStyle = {
  section: {
    background: "#EEEEEE",
    padding: "70px 0",
  },
  container,
  textCenter: {
    textAlign: "center",
  },
};

export default cognitiveStyle;
