import React from "react";
import PropTypes from "prop-types";

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import CustomInput from "components/CustomInput/CustomInput.js";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "assets/jss/material-kit-react/views/landingPageSections/registerMailStyle";
const useStyles = makeStyles(styles);

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

export class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { email: "" };
  }

  handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "newsletter", ...this.state }),
    })
      .then(() => alert("Thanks for registering!"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  render() {
    const { classes } = this.props;
    const { email } = this.state;
    const handleChangeEmail = this.handleChange;
    return (
      <Card>
        <form
          className={classes.form}
          onSubmit={this.handleSubmit}
          data-netlify="true"
          name="newsletter"
        >
          <input type="hidden" name="form-name" value="newsletter" />
          <CardBody className={classes.cardBody}>
            <h2 className={classes.title}>Work with us !</h2>
            <h4 className={classes.description}>
              Are you interested in being involved in the testing of the
              prototype of Live Planner? Register to get news about the project
              :
            </h4>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <CustomInput
                  labelText="Your Email"
                  id="email"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: email,
                    onChange: handleChangeEmail,
                  }}
                />
              </GridItem>
            </GridContainer>
          </CardBody>
          <CardFooter className={classes.cardFooter}>
            <Button type="submit" color="danger">
              Register for updates
            </Button>
          </CardFooter>
        </form>
      </Card>
    );
  }
}

ContactForm.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(ContactForm);
